import { Skeleton } from "@nokia-csf-uxr/ccfk";
import React, { Suspense } from "react";
import { IMoreActionButtonsProps } from "types";
const HorizontalMoreButtons = React.lazy(() => import("./HorizontalMoreButtons"));
const VerticalMoreButtons = React.lazy(() => import("./VerticalMoreButtons"));

/**
 * MoreActionButtons component
 * @param {string} layout
 * @param {function} onMenuClick
 * @param {object[]} menuItems
 * @returns {JSX.Element}
 */
const MoreActionButtons = ({
  layout = "vertical",
  onMenuClick,
  menuItems,
}: IMoreActionButtonsProps) => {
  return (
    layout === "vertical" ? (
      <Suspense fallback={<Skeleton width={100} height={30} />}>
      <VerticalMoreButtons onMenuClick={onMenuClick} menuItems={menuItems} />
      </Suspense>
    ) : (
      <Suspense fallback={<Skeleton width={50} height={10} />}>
      <HorizontalMoreButtons onMenuClick={onMenuClick} menuItems={menuItems} />
      </Suspense>
    )
  );
};

export default MoreActionButtons;
