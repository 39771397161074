export const mpwSolutionConfig = {
  mpwSolutionSteps: [
    "radio_solution_connectivity",
    "microwave_solution_connectivity",
  ],
  config:{
    common:{
      userConfig:["Radio Blueprint","Microwave Blueprint"],
      userExcelUpload:["Radio Excel Upload"],
      resetConfigurationFor:["Radio Excel Upload"],
      reserExcelFor:["Radio Blueprint"],
      totalCalculation:["radio_solution_connectivity"],
      excludeResetOnUndo:["count_of_rrh_types","count_of_microwave_configs"],
    },
    radio_solution_connectivity:{
      label:"Radio Configuration",
      configPackage:"rrh_configuration",
      configName:"rrh_config_name",
      configField:"RRH",
      configCountField:"count_of_rrh_types",
      configCountStateField:"isRrhTypesDisabled",
      solutionType:"radio_solution_type",
      excelJsonObject:"radioExcelJson"
    },
    microwave_solution_connectivity:{
      label:"Microwave Configuration",
      configPackage:"microwave_configuration",
      configName:"mw_config_name",
      configField:"MW",
      configCountField:"count_of_microwave_configs",
      configCountStateField:"isMicrowaveTypesDisabled",
      solutionType:"microwave_solution_type"
    }
  }
};
