import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button, { ButtonIcon, ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import AddIcon from "@nokia-csf-uxr/ccfk-assets/latest/AddIcon";
import ToggleButtonGroup, {
  ToggleGroupButton,
} from "@nokia-csf-uxr/ccfk/ToggleButtonGroup";
import { BREADCRUMB_ACTIONS } from "features/dashboard/utils/consstants";
import { setProperty } from "features/dashboard/store/dashboardSlice";
import { RootState } from "Store/mainStore";


const BreadcrumbActionRow = () => {
  // Sectors
  const dashboardSelector = useSelector((state:RootState) => state.dashboard);
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onActionToggle = (tabSelected) => {
    dispatch(setProperty({ key: "caseType", value: tabSelected }));
  }
  return (
    <div className="dsh-breadcrumb-actions">
      <ToggleButtonGroup>
      {
        BREADCRUMB_ACTIONS.map((tab) => (
          <ToggleGroupButton
            key={tab.id}
            aria-label={tab.name}
            isSelected={tab.id === dashboardSelector.data.caseType}
            onChange={() => onActionToggle(tab.id)}
            disabled={false}
            lastButton={false}
            firstButton={false}
            //tooltipProps={{ ...TOOLTIP_PROPS, disable: false, tooltip: button.text }}
          >
            <ButtonText>{tab.name}</ButtonText>
          </ToggleGroupButton>
        ))
      }
      </ToggleButtonGroup>
      <Button className="do-btn-success" onClick={()=> {console.log("redirect");navigate("/home")}}>
        <ButtonIcon>
          <AddIcon />
        </ButtonIcon>
        <ButtonText style={{ padding: "0 0.5rem" }}>New case</ButtonText>
      </Button>
    </div>
  );
};

export default BreadcrumbActionRow;
