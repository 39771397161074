import React, { useEffect, useState } from "react";

import { StatusBar, Tooltip } from "@nokia-csf-uxr/ccfk";
import {
  StatusBarPageGroup,
  StatusBarPagination,
  StatusBarPaginationIcon,
  StatusBarPaginationInput,
  StatusBarSection,
  StatusBarText,
  StatusBarTextTitle,
} from "@nokia-csf-uxr/ccfk/StatusBar";

import ChevronLeftIcon from "@nokia-csf-uxr/ccfk-assets/latest/ChevronLeftIcon";
import ChevronRightIcon from "@nokia-csf-uxr/ccfk-assets/latest/ChevronRightIcon";

import FirstPageIcon from "@nokia-csf-uxr/ccfk-assets/latest/ChevronFirstIcon";
import LastPageIcon from "@nokia-csf-uxr/ccfk-assets/latest/ChevronLastIcon";
import { PAGINATION_ITEMS_PER_PAGE } from "features/dashboard/utils/consstants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import _ from "lodash";
import { setProperty } from "features/dashboard/store/dashboardSlice";

const KEY_CODES = {
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
};
const EMPTY_STRING = "";
const START_PAGE = 1;
const TOTAL_PAGES = 10;

const CasePagination = () => {
  // Sectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  // Hooks
  const dispatch = useDispatch();

  // const [currentPage, setCurrentPage] = useState(
  //   dashboardSelector.data?.pagination?.["currentPage"]
  //     ? dashboardSelector.data?.pagination?.["currentPage"]
  //     : 1
  // );
  /// const [limitPerpage, setLimitPerpage] = useState(10);
  const validatePage = (page) => {
    if (page < START_PAGE) {
      return START_PAGE;
    }
    if (page > TOTAL_PAGES) {
      return TOTAL_PAGES;
    }

    return page;
  };

  useEffect(() => {
    if (!dashboardSelector.data?.pagination?.["itemsPerPage"]) {
      handleItemsPerPgeLimit(PAGINATION_ITEMS_PER_PAGE[0]);
    }
  }, []);

  const handleCurrentPageChange = ({ newPage }) => {
    // non-numeric page number not allowed
    if (isNaN(newPage) || newPage === EMPTY_STRING) return;
    const numericNewPage = parseInt(newPage);
    const validatedPage = validatePage(numericNewPage);
    // Dispatch the updated pagination object
    // Get current pagination object from dashboardSelector
    const __pagination = _.cloneDeep(dashboardSelector.data?.pagination);
    // Update the limit per page
    __pagination["currentPage"] = validatedPage;
    // Dispatch the updated pagination object
    dispatch(setProperty({ key: "pagination", value: __pagination }));
    //setCurrentPage(validatedPage);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === KEY_CODES.ARROW_DOWN ||
      event.key === KEY_CODES.ARROW_LEFT
    ) {
      handleCurrentPageChange({
        newPage: dashboardSelector.data?.pagination?.["currentPage"] - 1,
      });
    }
    if (
      event.key === KEY_CODES.ARROW_UP ||
      event.key === KEY_CODES.ARROW_RIGHT
    ) {
      handleCurrentPageChange({
        newPage: dashboardSelector.data?.pagination?.["currentPage"] + 1,
      });
    }
  };

  const handleItemsPerPgeLimit = (value) => {
    // Get current pagination object from dashboardSelector
    const __pagination = _.cloneDeep(dashboardSelector.data?.pagination);
    // Update the limit per page
    __pagination["itemsPerPage"] = parseInt(value);
    // Dispatch the updated pagination object
    dispatch(setProperty({ key: "pagination", value: __pagination }));
  };

  const filteredOffers = dashboardSelector.data?.caseResults?.[
    dashboardSelector.data?.["selectedStatus"]
  ]?.data
    ? dashboardSelector.data?.caseResults?.[
        dashboardSelector.data?.["selectedStatus"]
      ]?.data
    : [];

  return (
    <div className="dsh-case-paginattion">
      <StatusBar
        className="status-bar"
        style={{ justifyContent: "space-between" }}
      >
        <StatusBarSection>
          <StatusBarTextTitle>Items per page:</StatusBarTextTitle>
          <StatusBarText>
            <select
              className="item-per-page"
              value={
                dashboardSelector.data?.pagination?.["itemsPerPage"]
                  ? dashboardSelector.data?.pagination?.["itemsPerPage"]
                  : PAGINATION_ITEMS_PER_PAGE[0]
              }
              onChange={(event) => {
                handleItemsPerPgeLimit(event.target.value);
              }}
            >
              {PAGINATION_ITEMS_PER_PAGE.map((item) => (
                <option value={item.key} key={item.key}>
                  {item.value}
                </option>
              ))}
            </select>
          </StatusBarText>
        </StatusBarSection>

        <StatusBarPagination>
          <StatusBarPaginationIcon
            iconButtonProps={{
              "aria-label": "First Page",
              disabled:
                dashboardSelector.data?.pagination?.["currentPage"] ===
                START_PAGE,
              onClick: () => {
                handleCurrentPageChange({ newPage: START_PAGE });
              },
            }}
          >
            <FirstPageIcon />
          </StatusBarPaginationIcon>

          <StatusBarPaginationIcon
            iconButtonProps={{
              "aria-label": "Previous Page",
              disabled:
                dashboardSelector.data?.pagination?.["currentPage"] ===
                START_PAGE,
              onClick: () => {
                handleCurrentPageChange({
                  newPage:
                    dashboardSelector.data?.pagination?.["currentPage"] - 1,
                });
              },
            }}
          >
            <ChevronLeftIcon />
          </StatusBarPaginationIcon>
          <StatusBarPageGroup>
            <StatusBarTextTitle>Page</StatusBarTextTitle>
            <StatusBarPaginationInput
              onChange={(event) => {
                //@ts-ignore
                handleCurrentPageChange({ newPage: event.target?.value });
              }}
              onKeyDown={handleKeyDown}
              value={dashboardSelector.data?.pagination?.["currentPage"]}
              aria-label={`text box, ${dashboardSelector.data?.pagination?.["currentPage"]} pages`}
              aria-live="assertive"
            />
            <StatusBarText>{""}</StatusBarText>
          </StatusBarPageGroup>
          <StatusBarPaginationIcon
            iconButtonProps={{
              "aria-label": "Next Page",
              // disabled: dashboardSelector.data?.pagination?.["currentPage"] === TOTAL_PAGES,
              onClick: () => {
                handleCurrentPageChange({
                  newPage:
                    dashboardSelector.data?.pagination?.["currentPage"] + 1,
                });
              },
            }}
          >
            <ChevronRightIcon />
          </StatusBarPaginationIcon>

          {/* <StatusBarPaginationIcon
            iconButtonProps={{
              "aria-label": "Last Page",
              disabled: currentPage === TOTAL_PAGES,
              onClick: () => {
                handleChange({ newPage: TOTAL_PAGES });
              },
            }}
          >
            <LastPageIcon />
          </StatusBarPaginationIcon> */}
        </StatusBarPagination>
        <StatusBarSection>
          <StatusBarTextTitle>Count:</StatusBarTextTitle>
          <StatusBarText>
            <Tooltip
              style={{ display: "flex" }}
              closeOnReferenceHidden={false}
              placement="top"
              trigger="hover"
              tooltip="Number of records."
            >
              {filteredOffers.length}
            </Tooltip>
          </StatusBarText>
        </StatusBarSection>
      </StatusBar>
    </div>
  );
};

export default CasePagination;
