import { Button, ButtonsRow, Card, Label } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import TextInput, {
  TextInputLabelContent,
} from "@nokia-csf-uxr/ccfk/TextInput";
import SelectBar from "Components/Elements/SelectBar";
import {
  handleGetKeyFromObj,
  useRenderState,
} from "Components/Logics/ensoCommonFunctions";
import {
  handleAccountLogic,
  handleExceptionR6InternalLogic,
  handleRestLogic,
} from "Components/Logics/systemLogics";
import {
  ROLE,
  STATUS,
  caseCategory,
  caseSubTypes,
  caseTypesList,
  caseTypesMap,
  caseTypesPartnerList,
  nonSCRMCaseTypes,
  sCRMRegex,
} from "Components/Misc/CaseDetails_System";
import {
  caseTypesIcons,
  caseSubTypesIcons,
} from "Components/Misc/CaseDetailsIcon";
import { systemStyle } from "Constants/StyleLayer";
import OOT from "Constants/services/oot";
import {
  handleSellToParty,
  resetAuth,
  setError,
  setNetworkIds,
  setOOTAuth,
  setOOTStatus,
  showLoader,
} from "Store/general/auth";
import {
  resetCD,
  setOBJ,
  setStatus,
} from "Store/contentState/caseDetails/caseDetailsSlice";
import { setDataFC } from "Store/contentState/caseDetails/findCaseSlice";
import { RootState } from "Store/mainStore";
import { setLive, setTheme } from "Store/general/theme";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

export const FindCase = () => {
  let noscrmid = false;
  const state = useSelector((state) => state);
  const findCaseSelector = useSelector((state: RootState) => state.findCase);

  const authSelector = useSelector((state: RootState) => state.authSlice);
  const themeSelector = useSelector((state: RootState) => state.theme);
  // const { data: theme } = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();
  const handleSCRIM = ({ debounce = true, text }) => {
    if (text === "" || !sCRMRegex.test(text)) {
      dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BUDG }));
    }
    return dispatch(setDataFC({ key: "sCRMID", value: text }));
  };

  const handleFetchCase = async () => {
    dispatch(resetAuth());
    dispatch(resetCD());

    let scRMID = "19.XY.123456";
    if (nonSCRMCaseTypes.includes(findCaseSelector.data.caseType)) {
      dispatch(setDataFC({ key: "sCRMID", value: "" }));
      dispatch(setStatus(STATUS.IDLE));
      //dispatch(resetCD());
      // return;
    } else {
      dispatch(setStatus(STATUS.IDLE)); //show-caseDetails ->Bypassed #Exception,
    }

    if (findCaseSelector.data.sCRMID.length === 0) {
      noscrmid = true;
      dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BUDG }));
      dispatch(
        setError({
          key: "warn",
          value: {
            errorMsg: "No sCRM ID.Please enter the offer details manually.",
            errorCode: null,
          },
        })
      );
    }

    if (!sCRMRegex.test(findCaseSelector.data.sCRMID)) {
      //caseDetails
      // dispatch(
      //   setError({
      //     key: "error",
      //     value: {
      //       errorMsg: "Provided SCRIM ID format is invalid",
      //       errorCode: null,
      //     },
      //   })
      // );
    } else {
      //srcm will findCaseSelector.data.sCRMID
      scRMID = findCaseSelector.data.sCRMID;
    }
    dispatch(setStatus(STATUS.LOADING)); //caseDetails
    dispatch(setOOTStatus(STATUS.LOADING));
    dispatch(setOOTAuth({ data: {} })); //reset the oot response for getopp.
    dispatch(showLoader(true));
    await OOT.getOpportunity({ scrm_id: scRMID })
      .then((resp) => {
        if (resp.status) {
          dispatch(setStatus(STATUS.IDLE)); //caseDetails
          dispatch(setLive(true)); //weather request was good / bad
          // if the currency_check is true, then we need to set the currency to the default as empty
          const responseData = _.cloneDeep(resp.data);
          if(resp.data?.currency_check) {
            responseData.currency.default = "";
          }
          dispatch(setOOTAuth(responseData));
          Array.isArray(resp.data.sell_to_parties) &&
            dispatch(
              handleSellToParty(
                resp.data.sell_to_parties.map(
                  (e) =>
                    new Object({
                      id: e.nid,
                      value: e.name,
                      account_id: e.account_id,
                    })
                )
              )
            );
          dispatch(setOOTStatus(STATUS.IDLE));
          /*
          We are setting some value by own like below , but for others we are setting via so-called sysmtemLogics.js, name starts with handle
          */
         if(!_.includes(authSelector.userDatamart_role,'technical_sales_sales')) {
          dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BUDG }));
         } else {
           dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BIND }));
         }

          if (
            resp.data.end_customer.network_ids &&
            typeof resp.data.end_customer.network_ids === "object" &&
            Object.keys(resp.data.end_customer.network_ids).length > 0
          ) {
            const responseNetworkIds = resp.data.end_customer.network_ids;
            const networkids = [];
            for (const key in responseNetworkIds) {
              networkids.push({ id: key, value: responseNetworkIds[key] });
            }
            dispatch(setNetworkIds(networkids));
          } else {
            const casetypes = ["non_commercial_network", "internal_order"];
            const subtypes = [
              "subscriptions_renewal",
              "extension_commercial_network",
              "sw_small_item_addition",
            ];
            if (
              (casetypes.includes(findCaseSelector.data.caseType) &&
                subtypes.includes(findCaseSelector.data.subType)) ||
              findCaseSelector.data.caseType === "addition_to_existing_edge"
            ) {
              dispatch(
                setError({
                  key: "warn",
                  value: {
                    errorMsg: "No Network found.",
                    errorCode: null,
                  },
                })
              );
            }
          }
          // (resp.data.sales_channel.default) && dispatch(setOBJ({ path: "sales_channel", value: resp.data.sales_channel.default }));
          // (resp.data.currency.default) && dispatch(setOBJ({ path: "currency", value: resp.data.currency.default }));
          // (resp.data.market.default) && dispatch(setOBJ({ path: "market", value: resp.data.market.default }));
          /*
          @handleExceptionR6InternalLogic
          Runs When? this will set the accounts to nokia acc only when caseType = INT,
          else we need to set default list (which will be take care by user itself when he/she changes the caseType, and this will not run)
          */
          handleExceptionR6InternalLogic(state);
          /*
          @handleAccountLogic
          Runs When? Running when the default value of sales_channel.default is not empty,if set, Rules are set as per MVP
          */
          handleAccountLogic(resp, state);

          if (resp.errorCode && resp.errorCode === 204) {
            dispatch(
              setError({
                key: "warn",
                value: {
                  errorMsg: "Please enter the offer details manually.",
                  errorCode: null,
                },
              })
            );
            dispatch(
              setOBJ({ path: `caseCategory`, value: caseCategory.BUDG })
            );
          }

          if (resp.oot_status && resp.errorCode === "") {
            if (
              (resp.oot_status.type === "warning" ||
                (resp.oot_status.type === "info" &&
                  resp.oot_status.description.includes("INVALID_"))) &&
              !noscrmid
            ) {
              const descp =
                resp.oot_status.type === "info"
                  ? "Please add details manually"
                  : "";
              dispatch(
                setError({
                  key: "warn",
                  value: {
                    errorMsg: resp.oot_status.description + " " + descp,
                    errorCode: null,
                  },
                })
              );
              dispatch(
                setOBJ({ path: `caseCategory`, value: caseCategory.BUDG })
              );
            } else if (noscrmid) {
              dispatch(
                setOBJ({ path: `caseCategory`, value: caseCategory.BUDG })
              );
            }
          }
        } else {
          dispatch(
            setError({
              key: "error",
              value: {
                errorCode: resp.errorCode,
                errorMsg: resp.errorMessage,
              },
            })
          );
          dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BIND }));
        }
      })
      .catch((e) => {
        dispatch(setStatus(STATUS.IDLE)); //caseDetails
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: e,
              errorMsg: "Timeout",
            },
          })
        );
        dispatch(setLive(false));
        dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BIND }));
      });

    dispatch(showLoader(false));
    dispatch(setError("reset"));
    //dispatch(setOBJ({ path: `caseCategory`, value: caseCategory.BIND }));
  };

  const handleReset = () => {
    dispatch(
      setTheme({
        element: "select",
        comp: "subType",
        prop: "isOpen",
        value: null,
      })
    );
    dispatch(
      setTheme({
        element: "select",
        comp: "caseDetails_endCustomer",
        prop: "isDisabled",
        value: null,
      })
    );
    //handleSCRIM({ text: "", debounce: false });
    handleRestLogic();
    ///document.querySelector('#reset').firstElementChild.firstChild.firstChild.value=''
  };

  const handleSubTypes = () => {
    try {
      //dispatch(setError(null));
      var subTypeArr = [];
      if (findCaseSelector.data.caseType) {
        Object.keys(caseSubTypes).map((rad, index, arr) => {
          var currentCaseKey = Object.entries(caseTypesMap).find((z) =>
            z.includes(findCaseSelector.data.caseType)
          )[0];
          var currentCaseValue = Object.entries(caseTypesMap).find((z) =>
            z.includes(findCaseSelector.data.caseType)
          )[1];

          switch (findCaseSelector.data.caseType) {
            case currentCaseKey: //gives the value
              return caseSubTypes[currentCaseKey].map(
                (subType, indexSub, typesarr) => {
                  return (
                    <>
                      {subType.types.map((type, indexType) => {
                        subTypeArr.push(type);
                      })}
                    </>
                  );
                }
              );
              break;
            default:
              //  dispatch( setError({ key: 'warn', value: {errorMsg: 'Invalid Case Type',errorCode:'WFC10006' } }));
              break;
          }
        });
        var subKeys = subTypeArr.map((el) => el.key);
        const filteredSubTypes = subTypeArr.filter(
          ({ key }, index) => !subKeys.includes(key, index + 1)
        );
        return filteredSubTypes;
      }
      return subTypeArr;
    } catch (error) {
      dispatch(
        setError({
          key: "error",
          value: { errorMsg: error, errorCode: null },
        })
      );
    }
  };

  const renderSelect = useCallback((arr, ref) => {
    return handleGetKeyFromObj(arr, ref);
  }, []);

  // const isFormValid = useValidate();
  // const selectItemRef = useRef(null);
  // const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary();
  // const [subType, setSubType] = useState("");
  // window.addEventListenter('error', function(e) { alert(e.message); });

  /** getListIcon */
  const getListIcon = (key) => {
    if (!caseSubTypesIcons.get(key)) {
      return undefined;
    }
    return caseSubTypesIcons.get(key)();
  };

  /** getCaseTypeIcon */
  const getCaseTypeIcon = (key) => {
    if (!caseTypesIcons.get(key)) {
      return undefined;
    }
    return caseTypesIcons.get(key)();
  };

  const handleSubTypesArr = handleSubTypes().map(
    (e) =>
      new Object({
        id: e.key,
        value: e.value,
        type: e?.type,
        icon: e?.icon ? getListIcon(e.key) : null,
      })
  );

  const __caseTypesList =
    authSelector.role === ROLE.INTERNAL ? caseTypesList : caseTypesPartnerList;
  const handleCaseTypesArr = __caseTypesList.map((e) => {
    //@ts-ignore
    const idVal = e.id;
    return {
      id: idVal,
      //@ts-ignore
      value: e.value,
      icon: getCaseTypeIcon(idVal),
    };
  });

  // Adjust the grid gap for the findCaseCols
  let findCaseColsStyle = {...systemStyle.genrateGridCols(3)};
  findCaseColsStyle["gridGap"] = "0 1rem";
  return (
    <Card style={{ height: "100px" }} hoverEffect={false} focusEffect={false}>
      <div style={{ height: "100px" }}>
        <div
          id="findCase"
          style={systemStyle.flex({
            justify: "space-between",
            flex: "1",
            minHeight: "100%",
          })}
        >
          <div id="findCaseInputs">
            <div id="findCaseCols" style={findCaseColsStyle}>
              <div>
                <Label>
                  <TextInputLabelContent required>
                    Case type
                  </TextInputLabelContent>
                </Label>
                <SelectBar
                  handOnSelect={() => {
                    dispatch(setDataFC({ key: "subType", value: "" }));
                    dispatch(setDataFC({ key: "sCRMID", value: "" }));
                    const clearSubTypeEle: HTMLButtonElement =
                      document.querySelector("#ClearButton_subType");
                    if (clearSubTypeEle) {
                      clearSubTypeEle.click();
                    }
                  }}
                  // style={{ borderRadius: "20px" }}
                  key={"caseType"}
                  variant="outlined"
                  // isDisabled={loadedTool === "OOT"}
                  slice="findCase"
                  isOpen={themeSelector.data.select.isOpen.findCase_caseType}
                  options={handleCaseTypesArr}
                  renderValue={handleGetKeyFromObj(
                    handleCaseTypesArr,
                    findCaseSelector.data.caseType
                  )} //truevalue
                  optionPath="id"
                  displayKey={"value"}
                  setProperty={{
                    sliceProperty: "caseType",
                    themeProperty: "findCase_caseType",
                  }}
                />
              </div>

              <div>
                <div
                  style={{
                    visibility:
                      handleSubTypesArr.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <Label>
                    <TextInputLabelContent required>
                      Sub-type
                    </TextInputLabelContent>
                  </Label>
                </div>

                <div
                  style={{
                    visibility:
                      handleSubTypesArr.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <SelectBar
                    // style={{ borderRadius: "20px",width:"400px" }}
                    handOnSelect={() => dispatch(resetCD())}
                    key={"subType"}
                    variant="outlined"
                    // isDisabled={loadedTool === "OOT"}
                    slice="findCase"
                    isOpen={themeSelector.data.select.isOpen.findCase_subType}
                    options={handleSubTypesArr}
                    renderValue={handleGetKeyFromObj(
                      handleSubTypesArr,
                      findCaseSelector.data.subType
                    )} //truevalue
                    optionPath={"id"}
                    displayKey={"value"}
                    setProperty={{
                      sliceProperty: "subType",
                      themeProperty: "findCase_subType",
                    }}
                    // truncateListText={false}
                  />
                </div>
              </div>
              {useRenderState("form", "sCRMID") && (
                <div className="scrmIDBtnGroup TextBar">
                  <Label placeholder="19.XY.123456" className="m-0">
                    <TextInputLabelContent>sCRM ID</TextInputLabelContent>
                  </Label>

                  <TextInput
                    id={findCaseSelector.data.sCRMID}
                    value={findCaseSelector.data.sCRMID}
                    error={
                      !sCRMRegex.test(findCaseSelector.data.sCRMID.trim()) &&
                      findCaseSelector.data.sCRMID.length > 0
                    } //
                    errorMessage={
                      !sCRMRegex.test(findCaseSelector.data.sCRMID.trim()) &&
                      findCaseSelector.data.sCRMID.length > 0 &&
                      "Invalid sCRM ID"
                    }
                    variant={"outlined"}
                    placeholder={"19.XY.123456"}
                    onChange={(e) => {
                      handleSCRIM({
                        text: e.target.value.trim(),
                        debounce: false,
                      });
                    }}
                    //errorMessage={errorMessage}
                  ></TextInput>
                </div>
              )}
            </div>
          </div>
          <div id="findCaseActions">
            {useRenderState("form", "sCRMID") ? (
              <div className="scrmIDBtnGroup">
                <div className="customerInfoContainer">
                  <ButtonsRow>
                    <Button
                      // variant="outlined"
                      aria-label="settings"
                      onClick={() => {
                        handleReset();
                      }}
                    >
                      <ButtonText>Reset</ButtonText>
                    </Button>
                    <Button
                      // disabled={(!nonSCRMCaseTypes.includes(findCaseSelector.data.caseType)) && (!sCRMRegex.test(findCaseSelector.data.sCRMID))}

                      variant="call-to-action"
                      aria-label="settings"
                      onClick={() => {
                        if (
                          !sCRMRegex.test(findCaseSelector.data.sCRMID.trim())
                        ) {
                          //setTimeout(()=>dispatch(resetCD()),2000)
                        }
                        handleFetchCase();
                      }}
                    >
                      <ButtonText>
                        {sCRMRegex.test(findCaseSelector.data.sCRMID.trim())
                          ? "Find"
                          : "Confirm"}
                      </ButtonText>
                    </Button>
                  </ButtonsRow>
                </div>
              </div>
            ) : (
              <div
                className="ContinueBtnGroup"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <ButtonsRow>
                  <Button
                    // variant="outlined"
                    aria-label="settings"
                    onClick={() => {
                      handleReset();
                    }}
                  >
                    <ButtonText>Reset</ButtonText>
                  </Button>
                  <Button
                    // variant="outlined"
                    disabled={
                      handleSubTypes().length <= 1
                        ? !Boolean(findCaseSelector.data.caseType)
                        : !Boolean(findCaseSelector.data.subType)
                    }
                    //hidden={caseDetailsSelector.status === STATUS.IDLE}
                    onClick={() => {
                      handleFetchCase();
                    }}
                  >
                    <ButtonText>Continue</ButtonText>
                  </Button>
                </ButtonsRow>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
