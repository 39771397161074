import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { Skeleton, Tabs } from "@nokia-csf-uxr/ccfk";
// import CustomTab from "../Components/Tab/CustomTab";
import Planner from "../NDACPlanner/Planner";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { Tab } from "@nokia-csf-uxr/ccfk/Tabs";

interface IGenericTabView {
  fieldset: string[];
  renderDynamicContent?: Function;
  renderActionButton?: Function;
}

const GenericTabView = ({
  fieldset,
  renderDynamicContent,
  renderActionButton,
}: IGenericTabView) => {
  const [tabData, setTabData] = useState([]);
  const [searchDASButton, setSearchDASButton] = useState(false);
  const [tabSelected, setTabSelected] = useState("");
  const [laastTab, seLastTab] = useState("");
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );

  useEffect(() => {
    if (Array.isArray(fieldset) && fieldset.length > 0) {
      let lastTabId = "";
      let defaaultTabSelected = fieldset[0]["id"];
      const tabData = fieldset.map((field, __index) => {
        lastTabId = field["id"];
        return {
          label: field["label"] ? field["label"] : `Tab ${__index}`,
          tab: field["id"] ? field["id"] : `Tab${__index}`,
          index: __index,
          field: field,
          content: null, //getTabDynamicContent(field,lastTabIndex === __index), ----- This is causing the maximum call stack error
        };
      });
      if (tabData.length > 0) {
        setTabSelected(defaaultTabSelected);
        seLastTab(lastTabId);
        setTabData(tabData);
      }
    }
  }, []);

  useEffect(() => {
    if (
      configstepperSelector.data.active_step === "connectivity" &&
      dynamicSelector.data.element[configstepperSelector.data.active_step]
    ) {
      if (
        (dynamicSelector.data.element[configstepperSelector.data.active_step][
          "ibwave_input_available"
        ] || dynamicSelector.data.element[configstepperSelector.data.active_step][
          "ibwave_input_available_5g"
        ]) &&
        _.toLower(
          dynamicSelector.data.element[configstepperSelector.data.active_step][
            "ibwave_input_available"
          ]["key"]
        ) === "yes" || 
        _.toLower(
          dynamicSelector.data.element[configstepperSelector.data.active_step][
            "ibwave_input_available_5g"
          ]["key"]
        ) === "yes"
      ) {
        setSearchDASButton(true);
      } else {
        setSearchDASButton(false);
      }
    }
  }, [
    dynamicSelector.data.element?.[configstepperSelector.data.active_step]?.[
      "ibwave_input_available"
    ],dynamicSelector.data.element?.[configstepperSelector.data.active_step]?.[
      "ibwave_input_available_5g"
    ]
  ]);

  const currentField =
    fieldset[tabData.findIndex((tab) => tab.tab === tabSelected)];

  return (
    <>
      {tabData.length === 0 && <Skeleton />}
      {tabData.length > 0 && (
        <Tabs style={{ justifyContent: "start" }}>
          {tabData.map((tabs) => (
            <Tab
              key={`${tabs.tab}`}
              onSelect={() => {
                setTabSelected(tabs.tab);
              }}
              selected={tabs.tab === tabSelected}
            >
              {tabs.label}
            </Tab>
          ))}
        </Tabs>
      )}
      <div style={{ padding: "10px 0" }}>
        {tabSelected === "available_radio_network_plan_s" && (
          <div
            style={{ marginTop: "10px" }}
            key={`planner-available_radio_network_plan_s`}
          >
            <Planner />
          </div>
        )}
        {tabSelected !== "available_radio_network_plan_s" &&
          currentField?.["field_details"] &&
          _.isObject(currentField?.["field_details"]) && (
            <div style={{ marginLeft: "2%", marginTop: "10px" }}>
              {/* Render dynamic fields and pass tab id to customize the search block */}
              {renderDynamicContent(
                currentField?.["field_details"],
                false,
                undefined,
                currentField?.["id"],
                searchDASButton
              )}
              {laastTab === tabSelected && renderActionButton()}
            </div>
          )}
      </div>
    </>
  );
};

export default React.memo(GenericTabView);
