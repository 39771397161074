import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@nokia-csf-uxr/ccfk";
import nothingToshow from "features/dashboard/assets/svg/nothing-to-show.svg";

const NoContentPage = ({
  title = undefined,
  msg,
  actionText,
  actionhref,
  src,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          margin: "auto",
          gridColumn: "1 / -1", /* Span from the first column to the last */
          padding: "5rem 1rem",
        }}
      >
        <img alt="nothing-found" src={src ? src : nothingToshow} />
        {title && (
          <Typography
            style={{
              color: "var(--g-color-app-banner-primary-content)",
              fontSize: "var(--font-size-medium)",
              fontWeight: "700",
              margin: "20px 0 10px 0",
            }}
          >
            {title}
          </Typography>
        )}
        <p style={{ width: "80%", margin: "1rem auto" }}>
          <Typography>{msg}</Typography>
        </p>
        {/* <HorizontalDivider  className="m"/> */}
        {actionText && actionhref && (
          <Button variant="call-to-action" autoFocus onClick={actionhref}>
            {actionText}
          </Button>
        )}
      </div>
    </>
  );
};
//EmptyState.displayName = "EmptyState";
export { NoContentPage };
NoContentPage.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  actionText: PropTypes.string,
  actionhref: PropTypes.func,
  src: PropTypes.string,
};
