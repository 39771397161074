import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@nokia-csf-uxr/ccfk";
import AddIcon from "@nokia-csf-uxr/ccfk-assets/latest/AddIcon";
import emptyDashboard from "features/dashboard/assets/svg/emptyDashboard.svg";
import { ButtonIcon } from "@nokia-csf-uxr/ccfk/IconButton";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="dsh-lp-container">
        <div className="dsh-lp-background"></div>
        <div className="dsh-lp-content">
          <img alt="nothing-found" src={emptyDashboard} />
          <div>
            <Typography
              style={{
                color: "var(--g-color-app-banner-primary-content)",
                fontSize: "var(--font-size-medium)",
                fontWeight: "700",
                //margin: "20px 0 10px 0",
              }}
            >
              Nothing to show
            </Typography>
          </div>
          <div>
          <Typography
              style={{
                color: "var(--g-color-app-banner-primary-content)",
                fontSize: "var(--font-size-small)",
              }}
            >
              You haven’t yet created any case, click on create new to create a
              case
            </Typography>
          </div>
          <Button
            variant="call-to-action"
            autoFocus
            onClick={() => {
              navigate("/home");
            }}
          >
            <ButtonIcon>
              <AddIcon />
            </ButtonIcon>
            <ButtonText style={{ padding: "0 0.5rem" }}>
              Create new case
            </ButtonText>
          </Button>
        </div>
        {/* <HorizontalDivider  className="m"/> */}
      </div>
    </>
  );
};
export { LandingPage };
