import React from "react";
import { HorizontalDivider, VerticalDivider } from "@nokia-csf-uxr/ccfk";
import useDashboard from "features/dashboard/hooks/useDashboard";
import SearchOffer from "features/dashboard/components/Common/SearchOffer";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

const FilterToolbar = () => {
  // Selectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  // Hooks
  const { getFilterTabs, getDisplayLayoutTab } = useDashboard();

  return (
    <>
      <div className="dsh-ft-wrapper">
        {dashboardSelector.data.caseType !== "favourites" && (
          <div className="dsh-ft-tabs">{getFilterTabs()}</div>
        )}

        {dashboardSelector.data.caseType === "favourites" && (
          <div className="dsh-ft-tabs">
            <div className="dsh-ft-tabs-wrapper">
              <div className="dsh-ft-tab">Favourites</div>
            </div>
          </div>
        )}
        {dashboardSelector.data.caseType === "my_cases" && (
          <>
            <VerticalDivider />
            <div className="dsh-ft-view">{getDisplayLayoutTab()}</div>
          </>
        )}

        <div className="dsh-ft-search">
          <SearchOffer />
        </div>
      </div>
      <HorizontalDivider />
    </>
  );
};

export default FilterToolbar;
