import React, { Fragment } from "react";
import { AppToolbar } from "@nokia-csf-uxr/ccfk";
import { AppHeader } from "@nokia-csf-uxr/ccfk/App";
import {
  AppToolbarTitle,
  AppToolbarTitleContainer,
} from "@nokia-csf-uxr/ccfk/AppToolbar";
import Breadcrumbs, {
  BreadcrumbCompacts,
  BreadcrumbCompactsHeader,
} from "@nokia-csf-uxr/ccfk/Breadcrumbs";
import BreadcrumbActionRow from "./BreadcrumbActionRow";

const HeaderToolbar = () => {
  return (
    <AppHeader>
      <AppToolbar elevationProps={{ elevationIndex: 0 }}>
        {/* AppToolbarTitleContainer */}
        <AppToolbarTitleContainer
          style={{
            maxWidth: "100%",
            padding: "0",
            margin: "2px 0 0 2px",
          }}
        >
          <AppToolbarTitle>
            <Breadcrumbs className="dot__Breadcrumbs">
              <BreadcrumbCompacts>
                <BreadcrumbCompactsHeader>
                  <div className="dot-beadcrumb-navbar">
                  <div className="dot-beadcrumb-nav-item">Cases</div>
                  </div>
                </BreadcrumbCompactsHeader>
              </BreadcrumbCompacts>
            </Breadcrumbs>
          </AppToolbarTitle>
        </AppToolbarTitleContainer>
        {/* BreadcrumbActionRow */}
        <BreadcrumbActionRow />
      </AppToolbar>
    </AppHeader>
  );
};

export default HeaderToolbar;
