import { createSlice, unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import { fetchOffer } from "../services/dashboaardThunks";
import { FILTER_TOOLBAR_TABS, OFFER_STTATUS } from "../utils/consstants";

//
type TcaseAcctions = {
  action: string;
  selectedOffer: {};
};
interface DashboardState {
  data: {
    caseType: string | undefined;
    filterTab: string | undefined;
    viewType: string;
    offerSearch: string | undefined;
    pagination: {};
    caseResults: {} | undefined;
    caseActions: TcaseAcctions | {};
    isLoaderDialogOpen: boolean;
  };
  loading: boolean;
  error: string | null;
  users: string[];
}

const initialState: DashboardState = {
  data: {
    caseType: undefined,
    filterTab:
      FILTER_TOOLBAR_TABS[0] && FILTER_TOOLBAR_TABS[0].id
        ? FILTER_TOOLBAR_TABS[0].id
        : undefined,
    viewType: "grid",
    offerSearch: undefined,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
    },
    caseResults: {},
    caseActions: {},
    isLoaderDialogOpen: false,
  },
  loading: false,
  error: null,
  users: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setProperty(state, action) {
      state.data[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffer.fulfilled, (state, action) => {
        state.loading = false;
        let processedOfferData = action.payload;
        console.log("processedOfferData",processedOfferData)

        // state.data.caseResults = action.payload?.["data"] ? action.payload?.["data"] : {};
        state.data.caseResults = action.payload;
        //console.log(action.payload?.["data"])
        // process the offer data and update the state
        // console.log(action.payload?.["data"]);
        // 
        // processedOfferData = action.payload?.["data"].reduce((acc, item) => {
        //   const key = Object.keys(item)[0];
        //   acc[key] = item[key];
        //   return acc;
        // }, {});
        // if(state.data.caseType === "my_cases"){
        //   processedOfferData = action.payload?.["data"].reduce((acc, item) => {
        //     const key = Object.keys(item)[0];
        //     acc[key] = item[key];
        //     return acc;
        //   }, {});
        // }
          
           
        //console.log("processedOfferData",processedOfferData)
        // state.data["caseResults"] = processedOfferData && !_.isEmpty(processedOfferData) ? processedOfferData : {};
        //state.data.caseResults = processedOfferData && !_.isEmpty(processedOfferData) ? processedOfferData : {};
        //state.data.caseResults = action.payload?.["data"];
        // console.log('state.data',state.data);
      })
      .addCase(fetchOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = "true";
      });
  },
});

const processOfferData = (data) => {
  console.log("----", data);
  let filteredData = {};
  if (data.length > 0) {
    filteredData = data.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      acc[key] = item[key];
      return acc;
    }, {});
  }
  console.log("filteredData", filteredData);
  return filteredData;
};

export const { setProperty } = dashboardSlice.actions;

export default dashboardSlice.reducer;
