import { Skeleton } from "@nokia-csf-uxr/ccfk";
import React from "react";

const SKELTON_WIDTH = [
  100,
  200,  
  150,
  300,
  80,
]

const DashboardGridSkelton = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(5, 1fr)`,
        gap: "8px",
        alignItems: "center",
        padding: "var(--spacing-xx-large)",
      }}
    >
      {Array.from({ length: 5 }).map((_, colIndex) => (
        <Skeleton
          key={colIndex}
          //variant="rectangular"
          //width={SKELTON_WIDTH[colIndex]}
          height={24}
          style={{ borderRadius: "4px" }}
        />
      ))}
      {Array.from({ length: 5 }).map((_, rowIndex) => (
      Array.from({ length: 5 }).map((_, colIndex) => (
        <Skeleton
          key={`${rowIndex}-${colIndex}`}
          //variant="rectangular"
          //width={SKELTON_WIDTH[colIndex]}
          height={14}
          style={{ borderRadius: "4px" }}
        />
      ))
    ))}
    </div>
  );
};

export default DashboardGridSkelton;
