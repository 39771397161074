import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

import { Skeleton } from "@nokia-csf-uxr/ccfk";

import { RootState } from "Store/mainStore";
import FilterToolbar from "features/dashboard/components/Common//FilterToolbar";
// import AllCases from "./AllCases";
//import AllCaseSummary from "./AllCaseSummary";
import FavouriteCases from "./FavouriteCases";

// Conditional components are loading using lazy loading
const MyCases = lazy(() => import("./MyCases"));
const MyCasesStaatusFilter = lazy(() => import("../components/MyCases/MyCasesStaatusFilter"));
const AllCaseSummary = lazy(() => import("./AllCaseSummary"));
const AllCases = lazy(() => import("./AllCases"));


const CaseContent = () => {
  // Selectors
  const dashboardSelector = useSelector((state: RootState) => state.dashboard);
  return (
    <div className="dsh-case-contents">
      {dashboardSelector.data.caseType === "all_cases" && (
        <Suspense fallback={<Skeleton />}>
          <AllCaseSummary />
        </Suspense>
      )}
      <FilterToolbar />
      {dashboardSelector.data.caseType === "my_cases" && (
        <Suspense fallback={<Skeleton />}>
          <MyCasesStaatusFilter />
        </Suspense>
      )}
      <div
        className="dsh-case-contents-pages"
        //style={{ minHeight: "100px", maxHeight: "370px" }}
      >
        {dashboardSelector.data.caseType === "my_cases" && (
          <Suspense fallback={<Skeleton />}>
            <MyCases />
          </Suspense>
        )}
        {dashboardSelector.data.caseType === "all_cases" && (
          <Suspense fallback={<Skeleton />}>
            <AllCases />
          </Suspense>
        )}
        {dashboardSelector.data.caseType === "favourites" && (
          <Suspense fallback={<Skeleton />}>
            <FavouriteCases />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default CaseContent;
