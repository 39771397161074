import { Button } from '@nokia-csf-uxr/ccfk';
import FactoryResetIcon from '@nokia-csf-uxr/ccfk-assets/latest/FactoryResetIcon';
import BasketIcon from '@nokia-csf-uxr/ccfk-assets/legacy/BasketIcon';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { setTheme } from 'Store/general/theme';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProp } from "Store/Actions";

import { stepData } from 'Components/Logics/stateMachine/modules/PTDAC/system';
import { removeCartItem } from 'Store/contentState/cartBoqSlice';
import { undoHistory } from 'Store/contentState/stateMachine';
import { RootState, store } from "Store/mainStore";
import { batchGroupBy, getRemainingSteps } from 'Utils';
import _ from 'lodash';
import { setDataSet, setElement } from 'Store/contentState/configurationArea/dynamicSlice';
import { addToCartLogic } from 'Components/Content/configurationArea/Configurator/configurator_AddToCart';
import { useAddElement } from 'Components/Content/configurationArea/Configurator/hooks/useAddElements';
import { useConfigurator } from 'Components/Content/configurationArea/Configurator/hooks/useConfigurator';
import { mpwSolutionConfig } from 'Components/Content/configurationArea/Configurator/mpw/Config';

interface IAddCartBtn {
    onAction: Function,
    isLoading?: boolean,
    activePackage?: object,
    selectors?: object,
    allRefs?: object
}
const AddCartBtn = (props: IAddCartBtn) => {
    const dispatch = useDispatch()
    const stateMachineSelector = useSelector((state: RootState) => state.stateMachine);
    const themeSelector = useSelector((state: RootState) => state.theme);
    const configuratorSelector = useSelector((state: RootState) => state.config_stepper);
    const businessSelector = useSelector((state: RootState) => state.business);
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const { undoElement } = useAddElement();
    const { getAllPackageFields, getSubStepFieldNameList, getSubStepFieldDefaultValue } = useConfigurator();

    const undoStep = (step) => {
        const removeProducts = stateMachineSelector.PTDAC.history[step].filter((e) => e.cui_type != "extension"); //TODO: Nitesh- Think about it- why filter works.
        dispatch(removeCartItem({ products: removeProducts, parentSync: !false, unWrap: !false, history: true }))//TODO: Nitesh- Think about it - why false works.
        dispatch(undoHistory({ key: `PTDAC.history.${step}` }))
        dispatch(setTheme({ element: "button", comp: `${step}_undo`, prop: "isToggle", value: false }))
    }

    /** emptyBoqOptions */
    const emptyBoqOptions = () => {
        // empty the cart
        dispatch(setProp({ slice: "cartBoq", key: `cart.items`, value: [] }));
        //  empty the further steps selections
        const currentStep = configuratorSelector.data.active_step;
        const index_currentStep = _.findIndex(configuratorSelector.data.stepper_data, (obj) => (obj.stepname).toLowerCase() === currentStep)
        const furtherallSteps = _.map(_.slice(configuratorSelector.data.stepper_data, index_currentStep + 1, configuratorSelector.data.stepper_data.length - 1), 'stepname');

        // Remove linked planner - connectivity tab
        if (currentStep !== "connectivity" && _.includes(furtherallSteps, "connectivity")) {
            dispatch(
                setProp({
                    slice: "dynamic",
                    key: `reference_solution.linkedPlanner`,
                    value: {}
                })
            );
        }

        // if currentstep has count_of variables then reset them to 0 after undo
        const objEle = Object.entries(store.getState().dynamic.data.element[currentStep]);
        // Exclude mpwSolutionConfig.config.common.excludeResetOnUndo => count_of_microwave_configs & count_of_rrh_types
        const excludeReset = (mpwSolutionConfig?.config?.common?.excludeResetOnUndo) ? mpwSolutionConfig.config.common.excludeResetOnUndo : [];
        const currentstepCountVars = _.filter(objEle, (obj) => {
            return _.some(obj, (value, key) => {
                // Filter variaable starts with count_of, number_of, total_ which are not in excludeReset
                return (!excludeReset.includes(obj[0]) && (_.includes(obj[0], 'count_of') || _.includes(obj[0], 'number_of') || _.includes(obj[0], 'total_')));
            });
        });
        _.each(currentstepCountVars, (value, key) => {
            const elem = `element.${currentStep}.` + value[0];
            const count = { key: 0, value: 0 };
            dispatch(setProp({ slice: "dynamic", key: elem, value: count }));
        });

        // Handle sub step scenario
        setUndoSubStep(index_currentStep);
        // step undo functionality
        setStepUndoElemnt(furtherallSteps);
    }

    /** setStepUndoElemnt */
    const setStepUndoElemnt = (furtherallSteps) => {
        const packageData = getAllPackageFields();
        const currentStep = configuratorSelector.data.active_step;
        const currentStepIndex = _.findIndex(configuratorSelector.data.stepper_data, (obj) => (obj.stepname).toLowerCase() === currentStep);
        let substep_prevCurrElem = {};

        const currentFurtherSteps = [currentStep, ...furtherallSteps];
        // get all previous steps data
        let previousStepsData = _.pickBy(dynamicSelector.data.element, (obj, key) => !_.includes(currentFurtherSteps, key));
        if (configuratorSelector.data.stepper_data[currentStepIndex] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] === true) {
            substep_prevCurrElem = setPrevCurrSubStepDataElement(currentStepIndex);
        }
        // set options of further steps as empty and keep only the default fields
        let elementData = undoElement(props.allRefs, furtherallSteps, substep_prevCurrElem);
        // get options selected for previous steps, current steps, and previous substeps
        elementData.element = { ...elementData.element, ...substep_prevCurrElem, ...previousStepsData };
        // TODO: may be we dont need this storeElement , check and replace with element
        if (!_.isEmpty(elementData.element)) {
            // dispatch(setElement(elementData.element));

            if (!_.isEmpty(previousStepsData)) {
                // call add to cart with all the previous step data only
                let cartItems = {};
                cartItems = _.reduce(previousStepsData, (result, obj) => {
                    _.assign(result, obj);
                    return result;
                }, {});

                if (configuratorSelector.data.stepper_data[currentStepIndex] &&
                    configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] &&
                    configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] === true) {
                    const { currentSubStepFields, furtherSubStepFields } = getSubStepInfo(currentStepIndex);
                    // __packageData = {...packageData,...currentSubStepFields};
                    // Get all sub step for the further steps
                    // Prepare cart item
                    const __elementState = _.cloneDeep(dynamicSelector.data.element);
                    Object.keys(__elementState[currentStep]).forEach(key => {
                        if (!currentSubStepFields.includes(key) && !furtherSubStepFields.includes(key)) {
                            cartItems[key] = __elementState[currentStep][key];
                        }
                    });
                }
                dispatch(setProp({ slice: "dynamic", key: 'undoBoq', value: cartItems }));
                addToCartLogic(props.activePackage, packageData, props.selectors, cartItems);
            } else {
                dispatch(setProp({ slice: "dynamic", key: 'undoBoq', value: [] }));
                addToCartLogic(props.activePackage, packageData, props.selectors, []);
            }
            /**
             * Note : Important, dispatch of resetting further steps elements empty has to happen after add to cart
             * Else it will empty and then reset
             */
            dispatch(setElement(elementData.element));
        }
    }

    /** setUndoSubStep */
    const setUndoSubStep = (currentStepIndex) => {
        // Handle all the sub steps for the futher steps
        const furtherallSteps = _.map(_.slice(configuratorSelector.data.stepper_data, currentStepIndex + 1, configuratorSelector.data.stepper_data.length - 1), 'stepname');

        // Get all sub step for the further steps
        let __furtherSubSteps = [];
        let __currentSubSteps = [];
        let __stepsSubSteps = {}
        furtherallSteps.forEach(step => {
            const stepIndex = _.findIndex(configuratorSelector.data.stepper_data, (obj) => (obj.stepname).toLowerCase() === step.toString())
            if (configuratorSelector.data.stepper_data[stepIndex]["isChildActive"] &&
                configuratorSelector.data.stepper_data[stepIndex]["isChildActive"] === true) {
                __currentSubSteps = configuratorSelector.data.stepper_data[stepIndex]["subSteps"].map(sunStepObj => sunStepObj.stepname);
                __stepsSubSteps[step] = __currentSubSteps;
                __furtherSubSteps = [...__furtherSubSteps, ...__currentSubSteps];
            }
        });
        // Reset element data for all the step's sub steps
        const __elementState = _.cloneDeep(store.getState().dynamic.data.element);
        let newStepElementState = __elementState;
        Object.keys(__stepsSubSteps).forEach(step => {
            __stepsSubSteps[step].forEach(subStep => {
                const furtherSubStepFields = getSubStepFieldNameList(step, subStep);
                Object.keys(__elementState[step]).forEach(key => {
                    if (furtherSubStepFields.includes(key)) {
                        const defaultValue = getSubStepFieldDefaultValue(step, subStep, key);
                        setDefaultValueOnType(defaultValue, newStepElementState, key, step);
                    }
                });
            });
        });

        dispatch(
            setProp({
                slice: "dynamic",
                key: `element`,
                value: newStepElementState,
            })
        );

        // Check __furtherSubSteps includes mpwSolutionConfig.mpwSolutionSteps
        const includedMpwSolutionSteps = __furtherSubSteps.filter(element => mpwSolutionConfig.mpwSolutionSteps.includes(element));
        if(includedMpwSolutionSteps.length > 0){
            // Reset configCountField,configCountStateField & configField from each step
            includedMpwSolutionSteps.forEach(step => {
                const configPackage = mpwSolutionConfig.config[step].configPackage;
                const configCountField = mpwSolutionConfig.config[step].configCountField;
                const configCountStateField = mpwSolutionConfig.config[step].configCountStateField;
                const configField = mpwSolutionConfig.config[step].configField;
                dispatch(
                    setProp({
                        slice: "dynamic",
                        key: `element.connectivity.${configCountField}`,
                        value: {
                            key: "",
                            value: "",
                        },
                    })
                );
                dispatch(
                    setProp({
                        slice: "dynamic",
                        key: `modal.connectivity.${configCountStateField}`,
                        value: false,
                    })
                );
                dispatch(
                    setProp({
                        slice: "dynamic",
                        key: `modal.connectivity.${configPackage}.${configField}`,
                        value: {},
                    })
                );
            });
        }

        // Check for rrh configuration
        // if (__furtherSubSteps.includes("radio_solution_connectivity")) {
        //     // Reset count of rrh types
        //     dispatch(
        //         setProp({
        //             slice: "dynamic",
        //             key: `element.connectivity.count_of_rrh_types`,
        //             value: {
        //                 key: "",
        //                 value: "",
        //             },
        //         })
        //     );
        //     // Reset isRrhTypesDisabled
        //     dispatch(
        //         setProp({
        //             slice: "dynamic",
        //             key: `modal.connectivity.isRrhTypesDisabled`,
        //             value: false,
        //         })
        //     );
        //     // Remove RRH configuration from dynamic > data > modal > connectivity > radio_solution_connectivity
        //     dispatch(
        //         setProp({
        //             slice: "dynamic",
        //             key: `modal.connectivity.radio_solution_connectivity.RRH`,
        //             value: {},
        //         })
        //     );
        // }

        if (__furtherSubSteps.includes("radio_solution_connectivity") && !__currentSubSteps.includes("radio_solution_connectivity")) {
            // Remove radioExcelJson from dynamic > data > modal > connectivity > radio_solution_connectivity
            dispatch(
                setProp({
                    slice: "dynamic",
                    key: `modal.connectivity.radio_solution_connectivity.radioExcelJson`,
                    value: [],
                })
            );
        }

        // microwave_configuration related logic

        // toggle further steps undo flag
        const __toggleStepNames = __furtherSubSteps.map(subStepName => `${subStepName}_undo`);
        __toggleStepNames.forEach(undoKey => {
            dispatch(
                setTheme({
                    element: "button",
                    comp: `${undoKey}`,
                    prop: "isToggle",
                    value: false,
                })
            );
        });

        const currentStep = configuratorSelector.data.active_step;
        const currentSubstep = configuratorSelector.data.active_substep;
        // Handle undo for the sub step for the current step
        if (configuratorSelector.data.stepper_data[currentStepIndex] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] === true) {
            // Current step has sub step
            const { furtherSubStepsNames, currentSubStepFields, furtherSubStepFields } = getSubStepInfo(currentStepIndex);
            // Remove current sub step + further sub step fields from data set
            let __dataSet = _.cloneDeep(store.getState().dynamic.data.dataSet);
            if (!_.isEmpty(__dataSet)) {
                const __newDataSet = {};
                // Remove radioExcelJson in case radio_excel_technology is part of key list
                // if (currentSubStepFields.includes("radio_excel_technology") || 
                // furtherSubStepFields.includes("radio_excel_technology")) {
                //     let __tempDataSet = _.cloneDeep(dynamicSelector.data.dataSet);
                //     __dataSet = {};
                //     Object.keys(__tempDataSet).forEach(dk => {
                //         if(dk !== "radioExcelJson"){
                //             __dataSet[dk] = __tempDataSet[dk]
                //         }                        
                //     })
                // }
                // Remove radioExcelJson in case radio_solution_type is part of key list
                if ((currentSubStepFields.includes("radio_solution_type") ||
                    furtherSubStepFields.includes("radio_solution_type")) &&
                    (store.getState().dynamic.data.element[currentStep] && store.getState().dynamic.data.element[currentStep].radio_solution_type && store.getState().dynamic.data.element[currentStep].radio_solution_type.key) === "Radio Excel Upload") {
                    let __tempDataSet = _.cloneDeep(store.getState().dynamic.data.dataSet);
                    __dataSet = {};
                    Object.keys(__tempDataSet).forEach(dk => {
                        if (dk !== "radioExcelJson") {
                            __dataSet[dk] = __tempDataSet[dk]
                        }
                    })
                }
                Object.keys(__dataSet).forEach(key => {
                    if (!currentSubStepFields.includes(key) && !furtherSubStepFields.includes(key)) {
                        __newDataSet[key] = __dataSet[key];
                    }
                });
                dispatch(
                    setProp({
                        slice: "dynamic",
                        key: `dataSet`,
                        value: __newDataSet,
                    })
                );
            }

            // Remove field state for further steps
            const __elementState = _.cloneDeep(store.getState().dynamic.data.element);
            if (!_.isEmpty(__elementState) && !_.isEmpty(__elementState[currentStep])) {
                // Reset element data for all the step's sub steps
                let newStepElementState = __elementState[currentStep];
                furtherSubStepsNames.forEach(subStep => {
                    Object.keys(__elementState[currentStep]).forEach(key => {
                        const __furtherSubStepFields = getSubStepFieldNameList(currentStep, subStep)
                        if (__furtherSubStepFields.includes(key)) {
                            const defaultValue = getSubStepFieldDefaultValue(currentStep, subStep, key);
                            setDefaultValueOnType(defaultValue, newStepElementState, key);
                        }
                    });
                });
                dispatch(
                    setProp({
                        slice: "dynamic",
                        key: `element.${currentStep}`,
                        value: newStepElementState,
                    })
                );

                // toggle further steps undo flag
                const __toggleStepNames = furtherSubStepsNames.map(subStepName => `${subStepName}_undo`);
                __toggleStepNames.forEach(undoKey => {
                    dispatch(
                        setTheme({
                            element: "button",
                            comp: `${undoKey}`,
                            prop: "isToggle",
                            value: false,
                        })
                    );
                });
            }
            // Check for rrh configuration
            // if (furtherSubStepsNames.includes("radio_solution_connectivity")) {
            //     // Reset count of rrh types
            //     dispatch(
            //         setProp({
            //             slice: "dynamic",
            //             key: `element.connectivity.count_of_rrh_types`,
            //             value: {
            //                 key: "",
            //                 value: "",
            //             },
            //         })
            //     );
            //     // Reset isRrhTypesDisabled
            //     dispatch(
            //         setProp({
            //             slice: "dynamic",
            //             key: `modal.connectivity.isRrhTypesDisabled`,
            //             value: false,
            //         })
            //     );
            //     // Remove RRH configuration from dynamic > data > modal > connectivity > radio_solution_connectivity
            //     dispatch(
            //         setProp({
            //             slice: "dynamic",
            //             key: `modal.connectivity.radio_solution_connectivity.RRH`,
            //             value: {},
            //         })
            //     );
            //     // Remove radioExcelJson from dynamic > data > modal > connectivity > radio_solution_connectivity
            //     dispatch(
            //         setProp({
            //             slice: "dynamic",
            //             key: `modal.connectivity.radio_solution_connectivity.radioExcelJson`,
            //             value: [],
            //         })
            //     );
            // }
            const includedMpwSolutionSteps = __furtherSubSteps.filter(element => mpwSolutionConfig.mpwSolutionSteps.includes(element));
            if(includedMpwSolutionSteps.length > 0){
                // Reset configCountField,configCountStateField & configField for each step
                includedMpwSolutionSteps.forEach(step => {
                    const configPackage = mpwSolutionConfig.config[step].configPackage;
                    const configCountField = mpwSolutionConfig.config[step].configCountField;
                    const configCountStateField = mpwSolutionConfig.config[step].configCountStateField;
                    const configField = mpwSolutionConfig.config[step].configField;
                    dispatch(
                        setProp({
                            slice: "dynamic",
                            key: `element.connectivity.${configCountField}`,
                            value: {
                                key: "",
                                value: "",
                            },
                        })
                    );
                    dispatch(
                        setProp({
                            slice: "dynamic",
                            key: `modal.connectivity.${configCountStateField}`,
                            value: false,
                        })
                    );
                    dispatch(
                        setProp({
                            slice: "dynamic",
                            key: `modal.connectivity.${configPackage}.${configField}`,
                            value: {},
                        })
                    );
                });
            }
        }
        //# End of sub step undo logic
    }

    const setPrevCurrSubStepDataElement = (currentStepIndex) => {
        const currentStep = configuratorSelector.data.active_step;
        let storeElement = { [currentStep]: {} };

        if (configuratorSelector.data.stepper_data[currentStepIndex] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] &&
            configuratorSelector.data.stepper_data[currentStepIndex]["isChildActive"] === true) {

            const { furtherSubStepsNames, currentSubStepFields, furtherSubStepFields } = getSubStepInfo(currentStepIndex);

            const __elementState = _.cloneDeep(store.getState().dynamic.data.element);
            Object.keys(__elementState[currentStep]).forEach(key => {
                // set previous and current sub steps data elements
                if (!furtherSubStepFields.includes(key)) {
                    storeElement[currentStep][key] = __elementState[currentStep][key];
                }
                // set only default values of further substeps
                furtherSubStepsNames.forEach(subStep => {
                    if (furtherSubStepFields.includes(key)) {
                        storeElement[currentStep][key] = __elementState[currentStep][key];
                        const defaultValue = getSubStepFieldDefaultValue(currentStep, subStep, key);
                        setDefaultValueOnType(defaultValue, storeElement, key, currentStep);

                        // Added by : Pratima (01-08-24) 
                        // we need to make hidden elements data as 0 and not ""
                        //  else it will throw error
                        if (_.includes(key, 'count_of') || _.includes(key, '_consumption') || _.includes(key, 'total_')) {
                            storeElement[currentStep][key] = { key: 0, value: 0 };
                        }
                    }
                });
            });
        }
        return storeElement;
    }

    /** getSubStepInfo */
    const getSubStepInfo = (currentStepIndex) => {
        const currentStep = configuratorSelector.data.active_step;
        // Get sub steps
        const subSteps = configuratorSelector.data.stepper_data[currentStepIndex]["subSteps"];
        const currentSubStep = configuratorSelector.data.active_substep;
        const currentSubStepIndex = _.findIndex(subSteps, (obj) => (obj["stepname"]).toLowerCase() === currentSubStep.toLowerCase());
        // Get current sub step's filed list
        const currentSubStepFields = getSubStepFieldNameList(currentStep, currentSubStep);
        let prevSubStepFields = [];
        for (let i = 0; i < currentSubStepIndex; i++) {
            prevSubStepFields = [...prevSubStepFields, ...getSubStepFieldNameList(currentStep, subSteps[i]["stepname"])];
        }

        let furtherSubStepFields = [];
        const furtherSubStepsNames = [];
        for (let i = currentSubStepIndex + 1; i < subSteps.length; i++) {
            furtherSubStepFields = [...furtherSubStepFields, ...getSubStepFieldNameList(currentStep, subSteps[i]["stepname"])];
            furtherSubStepsNames.push(subSteps[i]["stepname"]);
        }
        return {
            furtherSubStepsNames, currentSubStepFields, furtherSubStepFields
        }
    }

    /** setDefaultValueOnType */
    const setDefaultValueOnType = (defaultValue, newStepElementState, key, step?) => {
        if (_.isArray(defaultValue)) {
            _.each(defaultValue, (val, index) => {
                step ? newStepElementState[step][key].push({ key: val, value: val }) : newStepElementState[key].push({ key: val, value: val });
            });
        } else {
            step ? newStepElementState[step][key] = { key: defaultValue, value: defaultValue } : newStepElementState[key] = { key: defaultValue, value: defaultValue };
        }
    }

    return (
        <>
            <div className='mt' style={{ display: 'flex', justifyContent: 'end' }}>
                {
                    (!(themeSelector.data.button.isToggle.hasOwnProperty(`${stateMachineSelector.PTDAC.activeTab}_undo`)) || !(themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`]))
                        ? (<Button disabled={props.isLoading} onClick={() => {
                            if (businessSelector.data.options.PTDAC.caseIndustry !== 'port') {
                                dispatch(setProp({ slice: "dynamic", key: 'boq', value: [] }));
                                dispatch(setProp({ slice: "cartBoq", key: `cart.items`, value: [] }));
                            }
                            batchGroupBy.start(stateMachineSelector.PTDAC.activeTab);
                            props.onAction();
                            batchGroupBy.end();
                            dispatch(setTheme({ element: "button", comp: `options_global`, prop: "isDisable", value: true }))
                            dispatch(setProp({ slice: "dynamic", key: 'actionNext', value: false }));
                            dispatch(setTheme({ element: "button", comp: `${stateMachineSelector.PTDAC.activeTab}_undo`, prop: "isToggle", value: true }))
                        }} variant="neutral"> <ButtonIcon>
                                <BasketIcon />
                            </ButtonIcon><ButtonText>Add To Cart</ButtonText></Button>) :

                        (<Button disabled={props.isLoading} onClick={() => {
                            dispatch(setTheme({ element: "button", comp: `options_global`, prop: "isDisable", value: false }))
                            dispatch(setTheme({ element: "button", comp: `${stateMachineSelector.PTDAC.activeTab}_undo`, prop: "isToggle", value: false }))
                            dispatch(setProp({ slice: "dynamic", key: 'actionNext', value: true }));
                            // if (businessSelector.data.options.PTDAC.caseIndustry !== 'port') {
                            dispatch(setProp({ slice: "dynamic", key: 'boq', value: [] }));
                            emptyBoqOptions();
                        }} variant="neutral"> <ButtonIcon>
                                <FactoryResetIcon />
                            </ButtonIcon><ButtonText>Undo Selection</ButtonText></Button>)
                }

            </div>
        </>
    )
}

export default React.memo(AddCartBtn)

AddCartBtn.propType = {
    onAction: PropTypes.func,
    isLoading: PropTypes.bool,
    activePackage: PropTypes.object,
    selectors: PropTypes.object
}