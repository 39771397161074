import { createAsyncThunk } from "@reduxjs/toolkit";
import DATAMART_API from "Constants/services/datamart";
import { IOfferFilterObject, IOfferProps } from "../types";
import { OFFER_STTATUS } from "../utils/consstants";

// Define an async thunk for fetching user data
export const fetchOffer = createAsyncThunk<
  IOfferProps[], // Return type (e.g., array of offers)
  IOfferFilterObject, // Argument type (currentPage)
  { rejectValue: string } // For rejected actions
>(
  "users/fetchOffer", // Action type
  async (offerFilterObj, thunkAPI) => {
    const {
      caseType,
      offerType,
      currentPage,
      itemsPerPage,
      sortOption,
      offerSearch,
    } = offerFilterObj;
    try {
      const dasboardParam = {
        limit: itemsPerPage,
        offset: (currentPage-1) * itemsPerPage,
        caseType,
        offerType,
        offerStatus: OFFER_STTATUS.map((status) => status.id),
        sort: sortOption,
        search: offerSearch,
      };
      const resp = await DATAMART_API.fetchOffer(dasboardParam);
      return resp; // Fulfilled action
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // Rejected action
    }
  }
);