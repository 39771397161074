import React, { useState, useRef } from "react";
// FF icon
import ChevronDownSmallIcon from "@nokia-csf-uxr/ccfk-assets/latest/ChevronDownSmallIcon";
import CloseSmallIcon from "@nokia-csf-uxr/ccfk-assets/latest/CloseSmallIcon";

import Label from "@nokia-csf-uxr/ccfk/Label";
import SelectItem, {
  SelectItemLabelContent,
  SelectListItem,
  SelectItemText,
  SelectItemButton,
  SelectItemClearButton,
  SelectItemBaseText,
} from "@nokia-csf-uxr/ccfk/SelectItem";
import { ISelectFieldProps } from "features/dashboard/types";

const isSelectionKeyPressed = (key) =>
  key && (key === ENTER_KEY || key === SPACE_KEY);
const ENTER_KEY = "Enter";
const SPACE_KEY = " ";

const PLACEHOLDER = "Select";

// const data = [
//   "Price (low to high)",
//   "Price (high to low)",
// ];

/** Example of non-searchable SelectItem with single selection. */
const SelectField = ({
  labelText,
  optionData,
  selectedItem,
  setSelectedItem,
  style,
}: ISelectFieldProps) => {
  // console.log("SelectField -> selectedItem", selectedItem);
  // console.log("SelectField -> selectedItem", optionData);
  const selectItemRef = useRef(null);
  const selectItemButtonRef = useRef(null);
  // const [value, setSelectedItem] = useState(optionData[0]);
  const [isOpen, setIsOpen] = useState(false);

  // handle list item selection and close dropdown list after item is selected
  const handleEvent = (index) => (event) => {
    const { type } = event;
    const theValue = optionData[index];
    if (type === "keydown") {
      if (isSelectionKeyPressed(event.key)) {
        setSelectedItem(theValue);
        // setValue(theValue);
        setIsOpen(false);
      }
    } else if (type === "click") {
      //setValue(theValue);
      setSelectedItem(theValue);
      setIsOpen(false);
    }
  };

  const renderClearButton = (props) => {
    return (
      <SelectItemClearButton
        aria-label="clear input"
        onClick={() => {
          //setValue(undefined);
          setSelectedItem(undefined);
          selectItemButtonRef.current && selectItemButtonRef.current.focus();
        }}
        {...props}
      >
        <CloseSmallIcon />
      </SelectItemClearButton>
    );
  };

  const renderSelectItemBase = (props) => {
    // only show ClearButton when there is at least 1 item selected and the menu is opened
    const showClearButton = selectedItem && isOpen;
    return (
      <SelectItemButton
        ref={selectItemButtonRef}
        placeholder={PLACEHOLDER}
        dropdownIcon={<ChevronDownSmallIcon />}
        inputProps={{
          value: selectedItem.key ? selectedItem.key : PLACEHOLDER,
        }}
        // inputProps={{ value: selectedItem.id ? selectedItem.id : PLACEHOLDER }}
        renderClearButton={showClearButton ? renderClearButton : undefined}
        role="combobox"
        {...props}
      >
        {selectedItem && (
          <SelectItemBaseText>{selectedItem.value}</SelectItemBaseText>
        )}
      </SelectItemButton>
    );
  };

  return (
    <>
      {labelText && labelText.length > 0 && (
        <Label
          id="selectitem-component-label"
          variant="vertical-layout"
          style={{ width: "auto", padding: "0  0.5rem" }}
        >
          <SelectItemLabelContent variant="default">
            {labelText}
          </SelectItemLabelContent>
        </Label>
      )}

      <SelectItem
        ref={selectItemRef}
        aria-labelledby="selectitem-component-label"
        aria-label={
          selectedItem?.value ? selectedItem.value.toString() : PLACEHOLDER
        }
        isOpen={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        truncateListText
        listProps={{ ulProps: { role: "listbox" } }}
        renderSelectItemBase={renderSelectItemBase}
        //style={{ width: "180px" }}
        style={style}
        //{...inputProps}
      >
        {optionData.map((item, x) => {
          return (
            <SelectListItem
              role="option"
              key={`${item.key}-${x}`}
              selected={selectedItem.key === item.key}
              onClick={handleEvent(x)}
              onKeyDown={handleEvent(x)}
              aria-label={item.value.toString()}
              //style={{width: '180px'}}
              //style={{ height: '3rem', minHeight: '3rem',  maxHeight: '3rem'}}
            >
              <SelectItemText style={{ padding: "00.5REM" }}>
                {item.value}
              </SelectItemText>
            </SelectListItem>
          );
        })}
      </SelectItem>
    </>
  );
};

export default SelectField;
