import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { setProp } from "Store/Actions";
import _ from "lodash";
import {
  Button,
  Checkbox,
  Dialog,
  HorizontalDivider,
  Skeleton,
} from "@nokia-csf-uxr/ccfk";
import {
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { useRenderDynamicElements } from "../hooks/useRenderDynamicElements";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { useAddElement } from "../hooks/useAddElements";
import { useLocalStateManagement } from "../hooks/useLocalStateManagement";
import { useDataCallBack } from "../hooks/useDataCallback";
import { useLoadUI } from "../hooks/useLoadUI";
import PageInlineFeedback from "../Components/PageInlineFeedback";
import { useStatesOfElement } from "../hooks/useStatesOfElement";
import { useConfigurator } from "../hooks/useConfigurator";
import classes from "./MPW.module.css";
import { mpwSolutionConfig } from "./Config";

interface IRadioConfigurationModal {
  isOpen: boolean;
  configNumber?: number;
  actionType?: String;
  popupConfig: {
    configCount: number;
    configuration: Object;
    fieldList: Object;
  };
  updateRadioConfiguration: Function;
  allRefs: any;
}

const DIALOG_HEIGHT = "80%";
const DIALOG_WIDTH = "50%";
const EXAMPLE_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
  zIndex: 100000,
};

const RadioConfigurationModal = ({
  isOpen,
  configNumber = 0,
  popupConfig,
  actionType,
  updateRadioConfiguration,
  allRefs,
}: IRadioConfigurationModal) => {
  // Selectors
  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);

  // Hooks
  const dispatch = useDispatch();
  const { renderDynamicContent } = useRenderDynamicElements(allRefs);
  const { addElement } = useAddElement();
  const { setRef, setState, getState } = useLocalStateManagement();
  const { addCustomCallBack } = useDataCallBack(allRefs);
  const { checkStatesFields } = useLoadUI(allRefs);
  const { getElementsStates } = useStatesOfElement(allRefs);

  // Local variable
  const activeStep = configstepperSelector.data.active_step;
  const activeSubStep = configstepperSelector.data.active_substep;
  const { configCount, fieldList } = popupConfig;

  const configField = mpwSolutionConfig.config[activeSubStep].configField;
  const configName = mpwSolutionConfig.config[activeSubStep].configName;

  /** requiredFieldList */
  const requiredFieldList = useMemo(() => {
    const __requiredFieldList = [];
    Object.keys(fieldList).forEach((key) => {
      const allStatesForElement = getElementsStates(fieldList[key]);
      if (
        allStatesForElement.visibleValue !== "none" &&
        fieldList[key].required
      ) {
        __requiredFieldList.push(key);
      }
    });
    return __requiredFieldList;
  }, [fieldList]);

  // Local state
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    configNumber === 0 ? 1 : configNumber
  );
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [dataStore, setDataStore] = useState(
    dynamicSelector.data.modal[activeStep] &&
      dynamicSelector.data.modal[activeStep][activeSubStep] &&
      dynamicSelector.data.modal[activeStep][activeSubStep][configField] &&
      dynamicSelector.data.modal[activeStep][activeSubStep][configField][currentStep]
      ? dynamicSelector.data.modal[activeStep][activeSubStep][configField][
          currentStep
        ]
      : undefined
  );

  const [isCopyConfigEnabled, setIsCopyConfigEnabled] = useState(false);
  const [isConfigCopied, setIsConfigCopied] = useState(false);

  // const { getAllPackageFields } = useConfigurator();

  // Prepare the dialog title
  const modalTitle = `${
    dynamicSelector.data.element[activeStep]?.[
      mpwSolutionConfig.config[activeSubStep].solutionType
    ]?.key
  } - ${mpwSolutionConfig.config[activeSubStep].label}`;

  // Component effects
  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 200); // Simulate a loading time

      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    if (
      dynamicSelector.data.modal[activeStep] &&
      dynamicSelector.data.modal[activeStep][activeSubStep] &&
      dynamicSelector.data.modal[activeStep][activeSubStep][configField] &&
      dynamicSelector.data.modal[activeStep][activeSubStep][configField][currentStep]
    ) {
      setDataStore(
        dynamicSelector.data.modal[activeStep][activeSubStep][configField][
          currentStep
        ]
      );
    }
  }, [
    dynamicSelector.data.modal[activeStep] &&
    dynamicSelector.data.modal[activeStep][activeSubStep] &&
    dynamicSelector.data.modal[activeStep][activeSubStep][configField] &&
    dynamicSelector.data.modal[activeStep][activeSubStep][configField][currentStep]
      ? dynamicSelector.data.modal[activeStep][activeSubStep][configField]
      : undefined,
    currentStep,
  ]);

  useEffect(() => {
    __onComponentMount();
  }, [popupConfig.configuration]);

  useEffect(() => {
    setIsPrevDisabled(currentStep === 1);
  }, [currentStep]);

  /** onComponentMount */
  const __onComponentMount = () => {
    // Prepare dynamic.data.modal for RRH
    const customConfig = {
      configuration: fieldList,
      configCount,
      currentStep,
      dataStore:
        dynamicSelector.data.modal[activeStep] &&
        dynamicSelector.data.modal[activeStep][activeSubStep] &&
        dynamicSelector.data.modal[activeStep][activeSubStep][configField]
          ? dynamicSelector.data.modal[activeStep][activeSubStep][configField]
          : undefined,
    };

    // Add new / Edit option logic
    if (configNumber > 0) {
      // Add new / Edit option logic
      customConfig.dataStore = customConfig.dataStore
        ? customConfig.dataStore[configNumber]
        : undefined;

      // Add a flag for retain field value for edit config
      customConfig["retainValue"] =
        actionType && actionType === "add" ? false : true;
      customConfig["configNumber"] = configNumber;

      const elementData = addElement(allRefs, customConfig);
      // Get lattest local state from the store - localStates > setOptionsData
      const optionsData = _.cloneDeep(getState("optionsData"));
      const configFieldLower = configField.toLowerCase();
      const stepOption = {
        ...optionsData[activeStep],
        [`${configFieldLower}_${configNumber}`]: elementData.storeElement["configuration"],
      };
      optionsData[activeStep] = stepOption;
      setState("setOptionsData", optionsData);

      // Prepare and dispatch the modal store data for RRH Configrations
      if (!_.isEmpty(elementData.element)) {
        const rrhConfig = _.cloneDeep(
          dynamicSelector.data.modal[activeStep][activeSubStep][configField]
        );
        const configVal = {};
        Object.keys(elementData.element["configuration"]).forEach((key) => {
          configVal[key] =
            typeof elementData.element["configuration"][key] !== "object"
              ? { key: "", value: "" }
              : elementData.element["configuration"][key];
        });
        rrhConfig[configNumber] = configVal;
        rrhConfig[configNumber][configName] = {
          key: popupConfig["form_name"] + configNumber,
          value: popupConfig["form_name"] + configNumber,
        };
        dispatch(
          setProp({
            slice: "dynamic",
            key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
            value: rrhConfig,
          })
        );
      }

      // Handle callback for the custom configurations
      let __optionKey = `${configFieldLower}_${configNumber}`;
      addCustomCallBack(
        true,
        "",
        "",
        fieldList,
        dynamicSelector.data.modal[activeStep][activeSubStep][configField][
          currentStep
        ],
        __optionKey,
        configNumber
      );
    } else {
      // Configuration based on Count of RRH Types selection
      const stepOption = {};
      for (let i = 1; i <= customConfig.configCount; i++) {
        const __customConfig = _.cloneDeep(customConfig);
        __customConfig.dataStore = __customConfig.dataStore
          ? __customConfig.dataStore[i]
          : undefined;
        __customConfig["retainValue"] = false;
        __customConfig["configNumber"] = i;

        const elementData = addElement(allRefs, __customConfig);
        const configOptionName = configField.toLowerCase();

        stepOption[`${configOptionName}_${i}`] = elementData.storeElement["configuration"];

        let __optionKey = `${configOptionName}_${i}`;
        // Handle callback for the custom configurations
        addCustomCallBack(
          true,
          "",
          "",
          fieldList,
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            currentStep
          ],
          __optionKey,
          i
        );

        // Prepare and dispatch the modal store data for RRH Configrations
        if (!_.isEmpty(elementData.element)) {
          const rrhConfig = {};
          for (let i = 1; i <= configCount; i++) {
            const configVal = {};
            Object.keys(elementData.element["configuration"]).forEach((key) => {
              configVal[key] =
                typeof elementData.element["configuration"][key] === "object"
                  ? elementData.element["configuration"][key]
                  : {
                      key: elementData.element["configuration"][key],
                      value: elementData.element["configuration"][key],
                    };
            });
            const configName = mpwSolutionConfig.config[activeSubStep].configName;
            rrhConfig[i] = configVal;
            rrhConfig[i][configName] = {
              key: popupConfig["form_name"] + i,
              value: popupConfig["form_name"] + i,
            };
          }

          dispatch(
            setProp({
              slice: "dynamic",
              key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
              value: rrhConfig,
            })
          );
        }
      }
      const optionsData = _.cloneDeep(getState("optionsData"));
      optionsData[activeStep] = { ...optionsData[activeStep], ...stepOption };
      setState("setOptionsData", optionsData);
    }
  };

  /** handlePagination */
  const handlePagination = (action) => {
    setLoading(true);
    if (action === "next") {
      // Handle copy configuration functionality
      if (isCopyConfigEnabled && currentStep === 1 && !isConfigCopied) {
        copyCofiguration();
      }
      setCurrentStep((currentStep) => currentStep + 1);
    } else {
      setCurrentStep((currentStep) => currentStep - 1);
    }
  };

  /** updateRadioConfigurationState */
  function updateRadioConfigurationState(item, value) {
    // Reset all the fields have direct impact on the current field value change
    resetRRHDependencyFields(item);
    // Custom update logic for Radio Configuration
    dispatch(
      setProp({
        slice: "dynamic",
        key: `modal.${activeStep}.${activeSubStep}.${configField}.${currentStep}.${item}`,
        value: value,
      })
    );
    const refval = value !== "" ? true : false;
    setRef(allRefs, item, refval);
    checkStatesFields(item, value, fieldList);
    let callBackList = _.cloneDeep(dynamicSelector.data.callBack);

    if (Object.keys(callBackList).length > 0) {
      let isCallbackEnabled = false;
      Object.keys(callBackList).forEach(function (itm) {
        Object.keys(callBackList[itm]).forEach(function (itemkey) {
          if (callBackList[itm][itemkey] === item) {
            isCallbackEnabled = true;
          }
        });
      });
      if (isCallbackEnabled) {
        const configOptionName = configField.toLowerCase();
        let optionKey = `${configOptionName}_${currentStep}`;
        addCustomCallBack(
          false,
          value,
          item,
          fieldList,
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            currentStep
          ],
          optionKey,
          currentStep
        );
      }
    }
  }

  /** resetRRHDependencyFields */
  const resetRRHDependencyFields = (currentField) => {
    const configOptionName = configField.toLowerCase();
    // Get Keys from RRH Configuration field list
    const __fieldKeyList = Object.keys(fieldList);
    // Reset dependancy field logic
    const __dependencyFields = [];
    __fieldKeyList.forEach((fieldKey) => {
      const currentFieldValue = fieldList[fieldKey];
      if (
        currentFieldValue.data_callback &&
        currentFieldValue.data_callback.args &&
        fieldKey !== "radio_technology"
      ) {
        const callbackArgs = Object.values(
          currentFieldValue.data_callback.args
        );
        if (callbackArgs.includes(currentField)) {
          __dependencyFields.push(fieldKey);
        }
      }
    });

    // Get stored options list
    const storedOptionsState = _.cloneDeep(
      dynamicSelector.data.localStates.setOptionsData
    );

    __dependencyFields.forEach((dF) => {
      // Reset the dependecy -  event > change > target_field
      dispatch(
        setProp({
          slice: "dynamic",
          key: `modal.${activeStep}.${activeSubStep}.${configField}.${currentStep}.${dF}`,
          value: { key: "", value: "" },
        })
      );
      // Reset the option for dependecy field
      storedOptionsState[activeStep][`${configOptionName}_${currentStep}`][dF] = [];
    });

    if (__dependencyFields.length > 0) {
      setState("setOptionsData", storedOptionsState);
    }
  };

  /** isValidConfiguration */
  const isValidConfiguration = (stepNumber) => {
    // Check all the required field key (rfk) has value in the store for the given <stepNumber>
    // dynamic > data > modal > connectivity <activeStep> radio_solution_connectivity <activeSubStep> > RRH > <currentStep>
    const __emptyFields = [];
    requiredFieldList.forEach((rfk) => {
      if (
        !(
          dynamicSelector.data.modal[activeStep] &&
          dynamicSelector.data.modal[activeStep][activeSubStep] &&
          dynamicSelector.data.modal[activeStep][activeSubStep][configField] &&
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            stepNumber
          ] &&
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            stepNumber
          ] &&
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            currentStep
          ][rfk] &&
          dynamicSelector.data.modal[activeStep][activeSubStep][configField][
            stepNumber
          ][rfk]["value"] !== ""
        )
      ) {
        __emptyFields.push(rfk);
      }
    });
    const isEmpty = __emptyFields.length === 0 ? true : false;
    return isEmpty;
  };

  /** isNextButtonDisabled */
  const isNextButtonDisabled = () => {
    let isDisabled = loading || currentStep === configCount;
    if (isDisabled) {
      return isDisabled;
    }
    // Check the mandatory fields list from the configuration fields for the <currentStep>
    isDisabled = !isValidConfiguration(currentStep);
    return isDisabled;
  };

  /** copyCofiguration */
  const copyCofiguration = () => {
    // Get form name
    const formName = popupConfig["form_name"];
    // Get saved 1st page details
    const savedData = _.cloneDeep(
      dynamicSelector.data.modal[activeStep][activeSubStep][configField][1]
    );
    const rrhConfig = {};
    const optionsData = _.cloneDeep(getState("optionsData"));
    const stepOption = {
      ...optionsData[activeStep],
    };
    const configOptionName = configField.toLowerCase();
    const savedOptions = stepOption[`${configOptionName}_1`];
    rrhConfig[1] = _.cloneDeep(savedData);
    for (let i = 2; i <= configCount; i++) {
      rrhConfig[i] = _.cloneDeep(savedData);
      rrhConfig[i][configName] = {
        key: `${formName}${i}`,
        value: `${formName}${i}`,
      };
      stepOption[`${configOptionName}_${i}`] = savedOptions;
    }
    // Update Option data
    optionsData[activeStep] = stepOption;
    setState("setOptionsData", optionsData);

    // Dispatch the RRH change
    dispatch(
      setProp({
        slice: "dynamic",
        key: `modal.${activeStep}.${[activeSubStep]}.${configField}`,
        value: rrhConfig,
      })
    );

    // Set the copy disabled
    setIsConfigCopied(true);
  };

  /** isSaveButtonDisabled */
  const isSaveButtonDisabled = () => {
    // return false; // Should be deleted
    let isDisabled = loading;
    if (!isDisabled) {
      // Validate all the step's mandatory fields are filled
      for (let i = 1; i <= configCount; i++) {
        // Check the mandatory fields list from the configuration fields for the step <i>
        const isStepFormValid = isValidConfiguration(i);
        if (!isStepFormValid) {
          return true;
        } else {
          isDisabled = false;
        }
      }
    }
    return isDisabled;
  };
  
  const renderConfig = {
    configCount,
    currentStep,
    dataStore,
    actionType,
    optionDataKey: `${configField.toLowerCase()}_${currentStep}`,
    sliceProperty: `modal.${activeStep}.${activeSubStep}.${configField}.${currentStep}`,
    customUpdateMethod: updateRadioConfigurationState,
  };

  return (
    <Dialog
      // appElement={appElementRef}
      isOpen={isOpen}
      ariaHideApp={false}
      style={{ content: EXAMPLE_ALERT_STYLE }}
      onRequestClose={(event) => {
        console.log(event);
      }}
      parentSelector={() => document.querySelector("#dot-react-portals")}
    >
      <DialogTitle 
        // title="Radio Blueprint - Radio Configuration"
        title={modalTitle} />
      <DialogContent
        isTopDividerVisible={false}
        isBottomDividerVisible={false}
        style={{ padding: "0.25rem 1.3125rem 0 1.5rem" }}
      >
        <div className={classes.radioConfigModalContent}>
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 0",
              }}
            >
              <Skeleton width={300} />
            </div>
          )}
          {/* {!loading && dataStore && ( */}
          {!loading && (
            <>
              <div>
                <TextInputLabelContent style={{ marginBottom: "5px" }}>
                  {configNumber === 0
                    ? `${mpwSolutionConfig.config[activeSubStep].label} ${currentStep}/${configCount}`
                    : `${mpwSolutionConfig.config[activeSubStep].label} ${configNumber}`}
                </TextInputLabelContent>
                <HorizontalDivider className="mb mt" />
                <div>
                  <PageInlineFeedback />
                </div>
                <div className={classes.dynamicConfigContent}>
                  {renderDynamicContent(fieldList, true, renderConfig)}
                </div>
              </div>
              {configCount > 1 && currentStep === 1 && configNumber === 0 && (
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <Checkbox
                    disabled={isConfigCopied}
                    checked={isCopyConfigEnabled}
                    onChange={(e) => {
                      setIsCopyConfigEnabled(e.target.checked);
                    }}
                  />
                  <TextInputLabelContent style={{ marginLeft: "0px" }}>
                    Copy the same configuration to all next configuration.
                  </TextInputLabelContent>
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          onClick={() => {
            updateRadioConfiguration("cancel");
          }}
        >
          Cancel
        </Button>
        {
          // Show prev & next button only if configCount > 1
          configCount > 1 && configNumber == 0 && (
            <>
              <Button
                onClick={() => handlePagination("prev")}
                disabled={loading || isPrevDisabled}
              >
                Previous
              </Button>
              <Button
                onClick={() => handlePagination("next")}
                disabled={isNextButtonDisabled()}
              >
                Next
              </Button>
            </>
          )
        }

        <Button
          disabled={isSaveButtonDisabled()}
          variant="call-to-action"
          autoFocus
          onClick={() => {
            updateRadioConfiguration("saved");
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default React.memo(RadioConfigurationModal);
