import React from 'react'
import DashboardGrid from '../components/Common/DashboardGrid'

const FavouriteCases = () => {
  return (
    <div className='dsh-olv-list-wrapper'>
      <DashboardGrid />
    </div>
  )
}

export default FavouriteCases