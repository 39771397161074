import { RootState, store } from "Store/mainStore";
import { useDispatch, useSelector } from "react-redux";
import { useConfigurator } from "./useConfigurator";
import { useFormatters } from "./useFormatters";
import _ from "lodash";
import { setTheme } from "Store/general/theme";
import { mpwSolutionConfig } from "../mpw/Config";

export const useAddElement = () => {
  const configstepperSelector = useSelector((state: RootState) => state.config_stepper);
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const stateMachineSelector = useSelector(
    (state: RootState) => state.stateMachine
  );
  const themeSelector = useSelector((state: RootState) => state.theme);
  const activeStep = configstepperSelector.data.active_step;
  const { getPackageFieldList,getContainerBasedElements } = useConfigurator();
  const { checkValue, getSelectOptions, getKeyvalueObj } = useFormatters();
  const dispatch = useDispatch();
  

  const addElement = (allRefs,customConfig=undefined) => {
    let element = {}
    let storeElement = {}
    let retainValue = false
    if (themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`] || (!_.isEmpty(customConfig) && customConfig.retainValue && customConfig.retainValue === true)) {
      retainValue = true
    }
    if(customConfig && !_.isEmpty(customConfig)){
      // customConfig for customizing the element
      let selectElement = {}
      let storeselectElement = {}
      const dataStore = customConfig.dataStore;
      if(customConfig.dataStore){
        Object.keys(customConfig.configuration).forEach(key=>{
          const data = [];
          data[0] = key;
          data[1] = customConfig.configuration[key];
  
          addEachFields(data, retainValue, selectElement, storeselectElement, configstepperSelector.data.active_step, allRefs,dataStore[key],customConfig.configNumber);  
  
          element['configuration'] = selectElement;
          storeElement['configuration'] = storeselectElement;
        });
      }      
      return { 'element': element, 'storeElement': storeElement };
    }
    
    let packagesList = configstepperSelector.data.solution_form_data['packages']
    
    Object.keys(packagesList).forEach(function (key) {
      let selectElement = {}
      let storeselectElement = {}
      const packageFieldDetails = getPackageFieldList(key);
      // Do not set elements for assumptions 
      if(key === 'assumptions'){
        selectElement = {markup:packageFieldDetails?.markup?.markup}
      }
      else{
        setElements(selectElement, storeselectElement, packageFieldDetails, allRefs, retainValue, key);
      }
      element[key] = selectElement;
      storeElement[key] = storeselectElement;
    });
    return { 'element': element, 'storeElement': storeElement };
  }

  const undoElement = (allRefs, furtherallSteps, substep_prevCurrElem) => {
    let element = {}
    let storeElement = {}
    let packagesList = configstepperSelector.data.solution_form_data['packages']
    let retainValue = false
    // if (themeSelector.data.button.isToggle[`${stateMachineSelector.PTDAC.activeTab}_undo`]) {
    //   retainValue = true
    // }
    Object.keys(packagesList).forEach(function (key) {
      const packageFieldDetails = getPackageFieldList(key);
      const storeData = store.getState();
      if (_.includes(furtherallSteps, key)) {
        let selectElement = {}
        let storeselectElement = {}
        setElements(selectElement, storeselectElement, packageFieldDetails, allRefs, retainValue, key);

        element[key] = selectElement;
        storeElement[key] = storeselectElement;
        if (((themeSelector.data.button.isToggle.hasOwnProperty(`config_${key}_undo`)) && (themeSelector.data.button.isToggle[`config_${key}_undo`]))) {
          dispatch(setTheme({ element: "button", comp: `options_global`, prop: "isDisable", value: false }));
          dispatch(setTheme({ element: "button", comp: `config_${key}_undo`, prop: "isToggle", value: false }))
        }
      } 
      else if (storeData.dynamic.data.element[key] && _.isEmpty(substep_prevCurrElem)) {
        element[key] = storeData.dynamic.data.element[key];
        storeElement[key] = storeData.dynamic.data.element[key];
      }
    });
    // setOptionsData(storeElement);
    return { 'element': element, 'storeElement': storeElement };
  }

  /** addEachFields */
  const addEachFields = (data, retainValue, selectElement, storeselectElement, key, allRefs,customStore=undefined,configNumber=undefined) => {
    selectElement[data[0]] = ""
    let fieldValues = [];
    
    if (retainValue) {
      fieldValues = (customStore) ? customStore : dynamicSelector.data.element[key][data[0]];
    }
    if (data[1]['type'] === 'range' || data[1]['type'] === 'number') {
      let dt = (dynamicSelector.data.elementMMS[key] && dynamicSelector.data.elementMMS[key][data[0]]) ? dynamicSelector.data.elementMMS[key][data[0]]['min'] : 0;
      if(configNumber && configNumber > 0){
        selectElement[data[0]] = (retainValue) ? checkValue(fieldValues) : {key:dt,value:dt};
      }
      else{
        selectElement[data[0]] = (retainValue) ? checkValue(dynamicSelector.data.dataSet[data[0]]) : {key:dt,value:dt};
      }
      if (data[1]['default'] !== "" && !retainValue) {
        selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
      }
    }
    if (data[1]['type'] === 'checkbox') {
      const { subtype, default: defaultValue } = data[1];
      if (subtype && subtype === 'toggle') {
        let fieldValue = null;
        if ([0, false, 'false'].includes(defaultValue)) {
          // Handle the case when data[1]["default"] is 0, false, or 'false'
          fieldValue = 0;
        } else if ([1, true, 'true'].includes(defaultValue)) {
          // Handle the case when data[1]["default"] is 1, true, or 'true'
          fieldValue = 1;
        }
        selectElement[data[0]] = { key: fieldValue, value: fieldValue }
        
      } else {
        selectElement[data[0]] = (retainValue) ? { key: fieldValues, value: fieldValues } : []
      }      
    }
    if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || (data[1]['type'] === 'checkbox' && data[1]['subtype'] !== 'toggle')) {
      // if (data[1]['type'] === 'checkbox' && data[1]['subtype'] && data[1]['subtype'] === 'toggle') {
      //   selectElement[data[0]] = { key: null, value: null }
      // }
      // else {
        let __options = data[1]['options'];
        let isCustOption = false;
        if(configNumber && retainValue){
          // Get configured configField from mpwSolutionConfig
          const active_substep = configstepperSelector.data.active_substep;
          const configField = mpwSolutionConfig.config?.[active_substep]?.["configField"] ? mpwSolutionConfig.config[active_substep]["configField"].toLowerCase() : "";
          isCustOption = dynamicSelector.data.localStates.setOptionsData[activeStep][`${configField}_${configNumber}`] && dynamicSelector.data.localStates.setOptionsData[activeStep][`${configField}_${configNumber}`][data[0]];
          __options = (dynamicSelector.data.localStates.setOptionsData[activeStep][`${configField}_${configNumber}`]) ? dynamicSelector.data.localStates.setOptionsData[activeStep][`${configField}_${configNumber}`][data[0]] : data[1]['options'];
        }
        storeselectElement[data[0]] = (configNumber && retainValue && isCustOption) ? __options: getSelectOptions(__options);
        
        if (data[1]['default'] !== "" && !retainValue) {
          if (!(_.has(data[1], 'multiple'))) {
            selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
          } else {
            selectElement[data[0]] = getKeyvalueObj(data[1]['default'])
          }
        }
        if (retainValue) {
          if (!(_.isArray(fieldValues))) {
            selectElement[data[0]] = fieldValues;

          } else if ((fieldValues).length >= 1) {
            selectElement[data[0]] = fieldValues
          }
        }
      // }
    }
    // if (data[1]['type'] === 'hidden' && _.includes(data[1]['id'], 'count_of') || _.includes(data[1]['id'], '_consumption')) {
    //   if (data[0] === 'commercial_model') {
    //     selectElement["commercial_model"] = { key: dynamicSelector.data.reference_solution.commercial_model, value: dynamicSelector.data.reference_solution.commercial_model }
    //   } else {
    //     selectElement[data[0]] = { key: 0, value: 0 };
    //   }
    // }
    // if field is hidden and has default value in it then add element
    if (data[1]['type'] === 'hidden' && _.includes(data[1]['id'], 'count_of') || _.includes(data[1]['id'], '_consumption') || _.includes(data[1]['id'], 'total_')) {
      if (data[0] === 'commercial_model') {
        selectElement["commercial_model"] = { key: dynamicSelector.data.reference_solution.commercial_model, value: dynamicSelector.data.reference_solution.commercial_model }
      } else {
        selectElement[data[0]] = { key: 0, value: 0 };
      }
    }
    if (key !== 'devices') {
      selectElement["commercial_model"] = { key: dynamicSelector.data.reference_solution.commercial_model, value: dynamicSelector.data.reference_solution.commercial_model }
    }
    if (data[0] === 'mxie_server_consumption') {
      selectElement[data[0]] = ""
    }
    allRefs.current[data[1]['id']] = false;
  }

  /** setElements */
  const setElements = (selectElement, storeselectElement, packageFieldDetails, allRefs, retainValue, key) => {
    Object.entries(packageFieldDetails).map((data, _index) => {
      if (data[1]['type'] === 'details' && data[1]['field_details']) {
        const subpackageDetails = getPackageFieldList(key,data[1]['id']);
        _.map(subpackageDetails,(subData,subIndex) => {
          const subfields = [];
          subfields[0] = subData.id;
          subfields[1] = subData;
          addEachFields(subfields, retainValue, selectElement, storeselectElement, key, allRefs)
        });
      } else {
        addEachFields(data, retainValue, selectElement, storeselectElement, key, allRefs);
      }
    });
  }

  return {
    addElement,
    undoElement
  }
}